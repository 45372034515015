<template>
  <b-modal
    id="modal-from-pago"
    v-model="modalPago"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mb-0-5">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Metodo de pago"
              rules="required"
            >
              <b-form-group
                :state="errors.length > 0 ? false : null"
                label="METODO DE PAGO:"
                label-cols="12"
                label-cols-lg="4"
              >
                <b-form-select
                  id="metodo_pago"
                  size="sm"
                  v-model="codigoMetodoPago"
                  value-field="codigo"
                  text-field="descripcion"
                  :options="metodoPagoItems"
                  @input="obtenerFormaPago"
                  :state="errors.length > 0 ? false : null"
                >
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              label="MONTO"
              label-for="monto"
              label-cols="12"
              label-cols-lg="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Monto"
                rules="required|positive"
              >
                <b-input-group prepend="Bs. " class="input-group-merge">
                  <b-form-input
                    id="monto"
                    type="number"
                    step="any"
                    lazy-formatter
                    :formatter="numberFormatter"
                    :state="errors.length > 0 ? false : null"
                    onClick="this.select();"
                    v-model.number="form.monto"
                    size="sm"
                  />
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="disabledCuentasTransaccion">
          <b-col cols="12" class="mb-0-5">
            <validation-provider
              #default="{ errors }"
              name="banco"
              rules="required|integer"
            >
              <b-form-group
                label="BANCO"
                label-cols="12"
                label-cols-lg="4"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-select
                  id="cuenta"
                  size="sm"
                  v-model="form.banco_id"
                  value-field="id"
                  text-field="descripcion"
                  :options="bancoItems"
                  :state="errors.length > 0 ? false : null"
                >
                </b-form-select>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="mb-0-5">
            <validation-provider
              #default="{ errors }"
              name="comrpobante de pago"
              rules="required"
            >
              <b-form-group
                label="COMPRO. DE PAGO:"
                label-cols="12"
                label-cols-lg="4"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="form.comprobante_pago"
                  size="sm"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group label="RECIBO:" label-cols="12" label-cols-lg="4">
              <b-form-input v-model="form.recibo" size="sm" />
            </b-form-group>
          </b-col>
        </b-row> -->
        <b-row class="mb-0-5">
          <b-col cols="12">
            <b-form-group
              disabled
              label="SALDO:"
              label-cols="12"
              label-cols-lg="4"
            >
              <b-input-group prepend="Bs. " class="input-group-merge">
                <b-form-input disabled :value="saldo" size="sm" />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import PagoServices from "../services/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import MetodoPagoServices from "@/modules/configuracion-sin/services/metodoPago";
import BancoServices from "@/modules/configuracion/banco/services/index";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    vSelect,
    BInputGroup,
  },
  props: ["item", "tipo"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        usuario_id: "",
        transaccion_id: "",
        comprobante_pago: "",
        recibo: "",
        monto: "",
        codigo_metodo_pago: "",
        metodo_pago: "",
        metodo_pago_id: "",
        banco_id: null,
        transaccion: "",
        sede_id: "",
        // caja_id: "",
      },
      saldo: 0,
      metodoPagoItems: [],
      codigoMetodoPago: "",
      bancoItems: [],
      required,
      submitted: false,
      errores: [],
      modalPago: false,
      estadoItems: [
        { text: "ACTIVO", value: 1 },
        { text: "INACTIVO", value: 0 },
      ],
      disabledCuentasTransaccion: false,
    };
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR PAGO" : "AGREGAR PAGO";
    },
  },
  watch: {
    "form.transaccion_id": function () {
      this.errores.transaccion_id = [];
    },
    "form.comprobante_pago": function () {
      this.errores.comprobante_pago = [];
    },
    "form.recibo": function () {
      this.errores.recibo = [];
    },
    "form.monto": function () {
      // this.errores.monto = [];
    },
    "form.codigo_metodo_pago": function () {
      this.errores.codigo_metodo_pago = [];
    },
    "form.metodo_pago": function () {
      this.errores.metodo_pago = [];
    },
    "form.banco_id": function () {
      this.errores.banco_id = [];
    },
    "form.sede_id": function () {
      this.errores.sede_id = [];
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    abrir(accion, transaccion, dataPago = []) {
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.usuario_id = this.mixing.id;
          this.form.transaccion_id = transaccion.id;
          this.form.comprobante_pago = "";
          this.form.transaccion = "";
          this.form.recibo = "";
          this.form.monto = "";
          this.form.codigo_metodo_pago = "";
          this.form.metodo_pago = "";
          this.form.metodo_pago_id = "";
          this.form.banco_id = null;
          this.form.sede_id = this.mixing.sede_id;
          this.modalPago = true;
          this.saldo = transaccion.saldo;
          this.codigoMetodoPago = "";

          // caja id
          // this.form.caja_id = this.mixing.caja.id;

          this.disabledCuentasTransaccion = false;
          break;
        }
        case "actualizar": {
          this.form.id = dataPago["id"];
          this.form.usuario_id = this.mixing.id;
          this.form.transaccion_id = dataPago["transaccion_id"];
          this.form.transaccion = dataPago["transaccion"];
          this.form.monto = dataPago["monto"];
          this.form.codigo_metodo_pago = dataPago["codigo_metodo_pago"];
          this.form.metodo_pago = dataPago["metodo_pago"];
          this.form.banco_id = dataPago["banco_id"];
          this.form.recibo = dataPago["recibo"];
          this.form.metodo_pago_id = dataPago["metodo_pago_id"];
          this.form.comprobante_pago = dataPago["comprobante_pago"];
          this.form.sede_id = dataPago["sede_id"];
          this.modalPago = true;
          this.saldo = (
            parseFloat(transaccion.saldo) + parseFloat(dataPago["monto"])
          ).toFixed(2);
          this.codigoMetodoPago = {
            codigo: dataPago["metodo_pago"].id,
            descripcion: dataPago["metodo_pago"].descripcion,
          };

          this.obtenerFormaPago1(this.codigoMetodoPago);
          break;
        }
      }

      this.getMetodosPago();
      this.getBanco();
      this.errores = [];
    },
    cerrarModal() {
      this.form.id = null;
      this.modalPago = false;
    },
    actualizarPago(item) {
      this.form.id = null;
      this.modalPago = false;
      this.$emit("actualizarPago", item);
      this.submitted = false;
    },
    // SERVICES
    async registrar() {
      console.log(this.form);
      try {
        if (this.tipo == "cobrar") {
          const response = await PagoServices.create_cuentas_cobrar(this.form);
          this.actualizarPago(response.data);
        } else {
          const response = await PagoServices.create_cuentas_pagar(this.form);
          this.actualizarPago(response.data);
        }
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      try {
        if (this.tipo == "cobrar") {
          const response = await PagoServices.update_cuentas_cobrar(
            this.form.id,
            this.form
          );
          this.actualizarPago(response.data);
        } else {
          const response = await PagoServices.update_cuentas_pagar(
            this.form.id,
            this.form
          );
          this.actualizarPago(response.data);
        }
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },

    async getMetodosPago() {
      try {
        const response = await MetodoPagoServices.get({ estado: 1 });
        const metodoPagoItem = response.data.map((g) => ({
          descripcion: g.descripcion,
          codigo: {
            codigo: g.id,
            descripcion: g.descripcion,
          },
          cod: g.id,
        }));
        let metodoPago = metodoPagoItem.filter((value) =>
          [1, 2, 5, 7].includes(value.cod)
        );
        this.metodoPagoItems = metodoPago;
      } catch (err) {
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    obtenerFormaPago(value) {
      if (value == null) {
        (this.form.metodo_pago_id = null), (this.form.metodo_pago = "");
      } else {
        if (value.descripcion.includes("TRANSFERENCIA BANCARIA")) {
          this.disabledCuentasTransaccion = true;
        } else {
          this.disabledCuentasTransaccion = false;
        }
        this.form.metodo_pago_id = value.codigo;
        this.form.metodo_pago = value.descripcion;
      }
      this.form.banco_id = null;
      this.form.comprobante_pago = "";
      this.form.recibo = "";
    },
    obtenerFormaPago1(value) {
      if (value.descripcion.includes("TRANSFERENCIA BANCARIA")) {
        this.disabledCuentasTransaccion = true;
      } else {
        this.disabledCuentasTransaccion = false;
      }
    },
    async getBanco() {
      try {
        const response = await BancoServices.get({});
        this.bancoItems = response.data;
      } catch (err) {
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>