<template>
  <div>
    <b-card
      :title="`Pagos - Cuentas por Pagar - Cliente: ${cliente_proveedor.razon_social}`"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="DETALLE DE TRANSACCIONES">
              <b-row class="mb-0-5">
                <b-col cols="12" md="6">
                  <div class="demo-inline-spacing">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      @click="verPdf(item.id)"
                      :disabled="buttonDisabled"
                      size="sm"
                    >
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span class="align-middle">VER TRANSACCION</span>
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      @click="listar($route.params.id)"
                      size="sm"
                    >
                      <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                      <span class="align-middle">RECARGAR</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>

              <ag-grid-vue
                :gridOptions="gridOptions"
                class="ag-theme-balham ag-grid-table-modal-cuentas-pagos"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                @grid-ready="onGridReady1"
                :rowData="items"
                colResizeDefault="shift"
                rowSelection="single"
                :animateRows="false"
                :floatingFilter="false"
                :pagination="false"
                :rowClassRules="rowClassRules"
                @first-data-rendered="onFirstDataRendered1"
                @selection-changed="onSelectionChanged"
                :pinnedBottomRowData="pinnedBottomRowData"
                :getRowStyle="getRowStyle"
                :alwaysShowVerticalScroll="true"
                :suppressPaginationPanel="true"
              >
              </ag-grid-vue>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="DETALLE DE PAGOS ">
              <b-row class="mb-0-5">
                <b-col cols="12">
                  <div class="demo-inline-spacing">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      size="sm"
                      v-if="$hascan('cuentas_pagar-registrar')"
                      :disabled="buttonDisabled"
                      @click="$refs.modalFormRef.abrir('registrar', item, [])"
                    >
                      <feather-icon icon="SaveIcon" class="mr-50" />
                      <span class="align-middle">AGREGAR PAGO</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      size="sm"
                      v-if="$hascan('cuentas_pagar-editar')"
                      :disabled="buttonDisabledPago"
                      @click="
                        $refs.modalFormRef.abrir('actualizar', item, itemPago)
                      "
                    >
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span class="align-middle">EDITAR PAGO</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      :disabled="buttonDisabledPago"
                      @click="confirmarEliminar(itemPago)"
                      size="sm"
                      v-if="$hascan('cuentas_pagar-eliminar')"
                    >
                      <feather-icon icon="XIcon" class="mr-50" />
                      <span class="align-middle">ELIMINAR PAGO</span>
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      @click="verPdfPago(itemPago.id)"
                      :disabled="buttonDisabledPago"
                      size="sm"
                    >
                      <feather-icon icon="EyeIcon" class="mr-50" />
                      <span class="align-middle">VER PAGO</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <ag-grid-vue
                :gridOptions="gridOptions1"
                class="ag-theme-balham ag-grid-table-modal-cuentas-pagos"
                :columnDefs="columnDefsPagos"
                :defaultColDef="defaultColDef"
                @grid-ready="onGridReady"
                :rowData="item.pago"
                colResizeDefault="shift"
                rowSelection="single"
                :animateRows="false"
                :floatingFilter="false"
                :pagination="false"
                :rowClassRules="rowClassRules"
                @first-data-rendered="onFirstDataRendered"
                @selection-changed="onSelectionChangedPagos"
                :alwaysShowVerticalScroll="true"
                :suppressPaginationPanel="true"
              >
              </ag-grid-vue>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <ModalFormPago
      ref="modalFormRef"
      @actualizarPago="actualizarPago"
      tipo="pagar"
    />
    <ModalPdfTransaccion
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :accionModalPdf="accionModalPdf"
      @cerrarModalPdf="cerrarModalPdf"
    />
  </div>
</template>
  
<script>
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";
import { AgGridVue } from "ag-grid-vue";
import Ripple from "vue-ripple-directive";
import Listado from "../views/Listado.vue";
import PagosServices from "../services/index";
import ModalFormPago from "../components/ModalFormPago.vue";
import ModalPdfTransaccion from "@/modules/components/ModalPdfTransaccion.vue";
import VentaServices from "@/modules/venta/services/index";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    Listado,
    BRow,
    BCol,
    BPagination,
    AgGridVue,
    ModalFormPago,
    ModalPdfTransaccion,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      items: [],
      item: [],
      cliente_proveedor: [],
      itemPago: [],
      buttonDisabled: true,
      searchQuery: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 1,
      from: 0,
      to: 0,
      total: 0,
      buttonDisabledPago: true,

      gridOptions: {
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      gridOptions1: {
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "FECHA HORA",
          field: "created_at",
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: false,
          headerCheckboxSelection: false,
          minWidth: 180,
          maxWidth: 180,
        },
        {
          headerName: "COMPROBANTE",
          // field: "comprobante",
          cellRenderer: function (param) {
            if (!param.node.rowPinned) {
              return (
                param.data.comprobante + " - " + param.data.numero_comprobante
              );
            }
          },
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "METODO PAGO",
          field: "metodo_pagos.descripcion",
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "TOTAL",
          field: "total",
          filter: false,
          minWidth: 90,
          maxWidth: 90,
          valueFormatter: numberFormatter,
          type: "numericColumn",
        },
        {
          headerName: "PAGO",
          field: "totalpago",
          filter: false,
          minWidth: 90,
          maxWidth: 90,
          valueFormatter: numberFormatter,
          type: "numericColumn",
        },
        {
          headerName: "SALDO",
          field: "saldo",
          filter: false,
          minWidth: 90,
          maxWidth: 90,
          valueFormatter: numberFormatter,
          type: "numericColumn",
        },
      ],
      columnDefsPagos: [
        {
          headerName: "FECHA HORA",
          field: "fecha",
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: false,
          headerCheckboxSelection: false,
          minWidth: 180,
          maxWidth: 180,
        },
        {
          headerName: "USUARIO",
          field: "usuario.nombre",
          filter: false,
          minWidth: 160,
          maxWidth: 160,
        },
        {
          headerName: "METODO PAGO",
          field: "metodo_pago.descripcion",
          filter: false,
          minWidth: 180,
          maxWidth: 180,
        },
        {
          headerName: "PAGO",
          field: "monto",
          filter: false,
          minWidth: 90,
          maxWidth: 90,
          valueFormatter: numberFormatter,
          type: "numericColumn",
        },
        {
          headerName: "COMRPBANTE DE PAGO",
          cellRenderer: function (param) {
            if (param.data.banco) {
              return (
                param.data.comprobante_pago +
                " - " +
                param.data.banco.descripcion
              );
            }
            return param.data.comprobante_pago;
          },
          // field: "comprobante_pago",
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "N° RECIBO",
          cellRenderer: function (param) {
            return "N° - " + param.data.id;
          },
          // field: "recibo",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
      ],

      // pdf
      accionModalPdf: false,
      pdf: "",
      pdfBase64: "",

      // seleccionar un solo registro
      id_transaccion: "",
    };
  },
  computed: {
    pinnedBottomRowData() {
      return [
        {
          metodo_pago: "SALDO TOTAL",
          saldo: this.calcularSaldo(),
          bold: true,
        },
      ];
    },
  },
  created() {
    this.getRowStyle = (params) => {
      if (params.node.rowPinned) {
        if (params.node.data.bold) {
          return { "font-weight": "bold" };
        }
      }
    };
  },
  methods: {
    calcularSaldo() {
      let resultado = 0;
      for (let index = 0; index < this.items.length; index++) {
        resultado = resultado + parseFloat(this.items[index].saldo);
      }
      resultado = resultado.toFixed(2);
      return resultado;
    },
    // SERVICES
    async listar(id) {
      this.loading = true;
      try {
        const response = await PagosServices.cuenta_pagar_proveedor(
          id,
          this.mixing.sede_id
        );
        this.items = response.data.transaccion;
        this.cliente_proveedor = response.data.cliente_proveedor;
        this.item = [];
        this.itemPago = [];
        this.buttonDisabled = true;
        this.buttonDisabledPago = true;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },

    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onGridReady1(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered1(params) {
      params.api.sizeColumnsToFit();
    },

    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows != "") {
        this.item = selectedRows[0];
        this.id_transaccion = selectedRows[0].id;
        this.itemPago = [];
        this.buttonDisabled = false;
        this.buttonDisabledPago = true;
      } else {
        this.item = [];
        this.itemPago = [];
        this.id_transaccion = "";
        this.buttonDisabled = true;
        this.buttonDisabledPago = true;
      }
    },

    onSelectionChangedPagos() {
      let selectedRows = this.gridApiPagos.getSelectedRows();
      if (selectedRows != "") {
        this.itemPago = selectedRows[0];
        this.buttonDisabledPago = false;
      } else {
        this.itemPago = [];
        this.buttonDisabledPago = true;
      }
    },
    async actualizarPago() {
      await this.listar(this.$route.params.id);
      let id = this.id_transaccion;
      this.gridApi.forEachNode(function (node) {
        node.setSelected(node.data.id === id);
      });
    },
    async eliminar(id) {
      try {
        const response = await PagosServices.destroy_cuentas_pagar(id);
        this.$bvToast.toast("Se elimino correctamente.", {
          title: "OK!",
          variant: "success",
          solid: false,
        });
        this.listar(this.$route.params.id);
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    confirmarEliminar(item) {
      this.$bvModal
        .msgBoxConfirm("Confirme que desea eliminar el pago: " + item.monto, {
          title: "ELIMINAR PAGO ?",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si, Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-dark",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.eliminar(item.id);
          }
        });
    },

    async verPdf(id) {
      try {
        const response = await VentaServices.findOnePdf(id);
        this.pdfBase64 = response.data.data;
        this.pdf =
          "data:application/pdf;base64," + response.data.data + "#toolbar=0";
        this.accionModalPdf = true;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },

    async verPdfPago(id) {
      try {
        const response = await PagosServices.findOnePdf(id);
        this.pdfBase64 = response.data.data;
        this.pdf =
          "data:application/pdf;base64," + response.data.data + "#toolbar=0";
        this.accionModalPdf = true;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    cerrarModalPdf() {
      this.accionModalPdf = false;
      this.pdf = "";
      this.pdfBase64 = "";
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.gridApiPagos = this.gridOptions1.api;
    this.gridColumnApiPagos = this.gridOptions1.columnApi;
    this.listar(this.$route.params.id);
  },
};
function numberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
</script>
  
<style lang="scss" scoped>
@import "@/assets/scss/components/agGridStyle.scss";
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
</style>
  