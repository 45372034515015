var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"id":"modal-from-pago","title":_vm.tituloModal,"no-close-on-backdrop":"","ok-disabled":_vm.submitted,"cancel-disabled":_vm.submitted,"no-close-on-esc":"","ok-title":"Guardar","ok-variant":"primary","cancel-variant":"outline-secondary","cancel-title":"Cerrar","button-size":"sm"},on:{"cancel":function($event){$event.preventDefault();return _vm.cerrarModal.apply(null, arguments)},"close":function($event){$event.preventDefault();return _vm.cerrarModal.apply(null, arguments)},"ok":function($event){$event.preventDefault();return _vm.validar.apply(null, arguments)}},model:{value:(_vm.modalPago),callback:function ($$v) {_vm.modalPago=$$v},expression:"modalPago"}},[_c('b-overlay',{attrs:{"show":_vm.submitted,"no-wrap":"","spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}}),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Metodo de pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"METODO DE PAGO:","label-cols":"12","label-cols-lg":"4"}},[_c('b-form-select',{attrs:{"id":"metodo_pago","size":"sm","value-field":"codigo","text-field":"descripcion","options":_vm.metodoPagoItems,"state":errors.length > 0 ? false : null},on:{"input":_vm.obtenerFormaPago},model:{value:(_vm.codigoMetodoPago),callback:function ($$v) {_vm.codigoMetodoPago=$$v},expression:"codigoMetodoPago"}})],1)]}}])})],1)],1),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"MONTO","label-for":"monto","label-cols":"12","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Monto","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"Bs. "}},[_c('b-form-input',{attrs:{"id":"monto","type":"number","step":"any","lazy-formatter":"","formatter":_vm.numberFormatter,"state":errors.length > 0 ? false : null,"onClick":"this.select();","size":"sm"},model:{value:(_vm.form.monto),callback:function ($$v) {_vm.$set(_vm.form, "monto", _vm._n($$v))},expression:"form.monto"}})],1)]}}])})],1)],1)],1),(_vm.disabledCuentasTransaccion)?_c('b-row',[_c('b-col',{staticClass:"mb-0-5",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"banco","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"BANCO","label-cols":"12","label-cols-lg":"4","state":errors.length > 0 ? false : null}},[_c('b-form-select',{attrs:{"id":"cuenta","size":"sm","value-field":"id","text-field":"descripcion","options":_vm.bancoItems,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.banco_id),callback:function ($$v) {_vm.$set(_vm.form, "banco_id", $$v)},expression:"form.banco_id"}})],1)]}}],null,false,912751449)})],1),_c('b-col',{staticClass:"mb-0-5",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"comrpobante de pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"COMPRO. DE PAGO:","label-cols":"12","label-cols-lg":"4"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.form.comprobante_pago),callback:function ($$v) {_vm.$set(_vm.form, "comprobante_pago", $$v)},expression:"form.comprobante_pago"}})],1)]}}],null,false,3434099566)})],1)],1):_vm._e(),_c('b-row',{staticClass:"mb-0-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"disabled":"","label":"SALDO:","label-cols":"12","label-cols-lg":"4"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"Bs. "}},[_c('b-form-input',{attrs:{"disabled":"","value":_vm.saldo,"size":"sm"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }